import React from "react";
import './NewCardCollageSection.css';
import { CollageImageLeftOne, CollageMiddleImgFive, CollageMiddleImgFour, CollageMiddleImgSix, CollageMiddleImgThree, CollageRightUpTwo } from "../../../Images";


const NewCardCollageSection = () => {

    const handleCardClick  = (categoryName) => {
        const baseUrl = 'https://evouchr.com/vouchers';
        if(categoryName){
            // window.open(`${baseUrl}/${categoryName}`, '_blank');
            window.open(`${baseUrl}`, '_blank');
          }else {
            window.open(`${baseUrl}`, '_blank');
          }
    }
    return(
            <>
                <div className="MainCardCollageSection">
                        <h2 className="MainHeadingTxtSect"> Explore Crypto Voucher Gift Card</h2>
                    <div className="SecondaryContentCollege">
                        <div className="FirstCardCollage" onClick={() => handleCardClick('Travel & Entertainment')}>
                            <h3 className="FirstCardCollHeadTxt"> Travel & Entertainment</h3> 
                            <img src={CollageRightUpTwo} className="CollageImageOne"/>
                            <div className="FirstCardCollegeDesc">
                                <p>
                                Travel across the globe and immerse yourself in a myriad of entertainment experiences, all made possible through our travel and entertainment gift cards. Explore the world and create unforgettable memories, all with the ease and convenience of cryptocurrency transactions.
                                </p>
                            </div>
                        </div>
                        <div className="SecondMainCardCollageSect">
                            <div>
                                <div className="SecondCardCollage" onClick={() => handleCardClick('Gaming')}>
                                    <div className="SecondCollageHeadTxt">
                                        {/* <h3>Game On With Crypto</h3> */}
                                        <h3>Stay Connected On-The-Go </h3>
                                        <p>Evouchr eSIMs are seamlessly activated and stay connected without the hassle of changing your physical SIM card, all purchasable with cryptocurrencies.</p>
                                        {/* <p>Get access to popular gaming platforms, in-game purchases, and subscription services using crypto.</p> */}
                                    </div>

                                    <div>
                                        <img src={CollageMiddleImgFive} className="CollageImageTwo" />
                                        {/* <img src={CollageImageLeftOne} className="CollageImageTwo" /> */}
                                    </div>
                                </div>

                                <div className="ThirdSetionCards">
                                    <div className="ThirdCardCollageSect" onClick={() => handleCardClick('Travel & Entertainment')}>
                                        <div className="ThirdSectinMainImg">
                                            <img src={CollageMiddleImgFour} className="CollageImageThree"/>
                                        </div>
                                        <div className="ThirdSecHeadTxt">
                                            <h3> Virtual Cards</h3>
                                            <p>
                                                Experience the convenience for various online transactions, from digital subscriptions to online purchases, all accessible with crypto.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="FourthSectionCards">
                                        <div className="FourthCardCollageSect" onClick={() => handleCardClick('Food & Restaurants')}>
                                            <div className="ThirdSecHeadTxt"> 
                                                {/* <h3>Stay Connected On-The-Go </h3> */}
                                                <h3>Game On With Crypto </h3>
                                                <p>Get access to popular gaming platforms, in-game purchases, and subscription services using crypto.</p>
                                                {/* <p>Evouchr eSIMs are seamlessly activated and stay connected without the hassle of changing your physical SIM card, all purchasable with cryptocurrencies.</p> */}
                                            </div>

                                            <div className="ThirdSectinMainImg">
                                                <img src={CollageImageLeftOne} className="CollageImageFour"/>
                                                {/* <img src={CollageMiddleImgFive} className="CollageImageFour"/> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="CardCollageMainRemContent">
                        <div className="CardCollageCardFive" onClick={() => handleCardClick('Fashion & Lifestyle')}>
                            <div>
                                <div>
                                    <img src={CollageMiddleImgSix} className="CollageImageFifth"/>
                                </div>
                                <div className="FithSectMainHeadTxt">
                                    <h3 >Fashion & Lifestyle</h3>
                                    <p className="FithSectMainSecTxt">
                                    Explore the world of fashion with our wide range of gift cards from top premier brands across the globe, using cryptocurrencies.
                                    </p>
                                </div>
                                
                            </div>
                        </div>

                        <div className="CardCollageCardSixth" onClick={() => handleCardClick('Mobile Recharge')}>
                            <div>
                                <div className="FithSectMainHeadTxt">
                                    <h3 > Mobile Refills</h3>
                                    <p className="FithSectMainSecTxt">
                                       Stay connected on the go by easily topping up your mobile phone with crypto.
                                    </p>
                                </div>

                                <div>
                                    <img src={CollageMiddleImgThree}className="CollageImageSixth"/>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </>
    )
}

export default NewCardCollageSection