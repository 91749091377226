import React from "react";
import '../TermsAndCondition.css';

import TermsNdConditionbannerImg from '../TermsNdConditionImages/React-Terms-and-Condition.webp';

import { Helmet } from "react-helmet";

const MobileTermsNdCondition = () =>{

    
    <Helmet>
        <meta charSet="utf-8" />
        <title> Terms and Conditions - Evouchr</title>
        <meta name="description"
        content="Purchase crypto e-commerce & game gift cards or payment cards & mobile topups with bitcoins and about 100 altcoins. We offer premium brands in over the world" />
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
    </Helmet>


    return(
        <>
            <div>
                <div className="PrivacypolicyImageContent">
                    <img src={TermsNdConditionbannerImg} className="privacybanerimg"/>
                </div>

   

                <div className="PrivacyPlcyMnTxtHead">
                    <div>
                        <div className="MainPrivacyTitleTxt">
                            <h2 className="PrivacyTitleTxt">Welcome to Evouchr!</h2>
                        </div>
                        <div className="MainPrivcyPageDesc TextStyleDesign">
                            <p>
                                These are the Terms and Conditions (“Terms”) applicable to all services and products offered on Evouchr. Please read them carefully, as well as our Privacy Policy, before proceeding, as they set out the contractual basis for any transactions occurring on Evouchr. "EVOUCHR" is an e-commerce platform accessible via the website www.Evouchr.com or via the app Evouchr.
                            </p>
                            <p>
                                By continuing to use Evouchr, you (“Customer”) are acknowledging that you have read and agree to the Terms, as well as to the Privacy Policy, when purchasing services and products from Evouchr.
                            </p>
                        </div>
                    </div>

                    <div>
                        <hr/>
                    </div>

                    <div className="PrivacytextOne">
                        <div className="MainPrivacyTitleTxt">
                            <h2 className="PrivacyTitleTxt">Partnerships and Counterparties</h2>
                        </div>
                        <div className="MainPrivcyPageDesc TextStyleDesign">
                            <p>
                                These Terms are agreed between you and the following counterparty, depending on what you are using Evouchr for:
                            </p>

                            <p>
                                EVOUCHR is a vertical of the VirtualMinds Portal, a company with huge expertise in leadership across diverse industries. Our mission was to make the everyday use of cryptocurrencies more convenient for people. The concept of utilizing cryptocurrency for gift cards, bill payments, and mobile top-ups on a global scale was born from this vision. We have assembled a highly experienced and diverse team dedicated to ensuring a seamless journey for our users.

                                {/* You can contact the Evouchr directly at <a href="https://evouchr.com/contactus/" target="_blank" className="HyperLinkDesign"> www.evouchr.com/contactus </a> */}
                            </p>

                        </div>
                    </div>

                    <div>
                        <hr/>
                    </div>


                    <div className="PrivacytextOne">
                        <div className="MainPrivacyTitleTxt">
                            <h2 className="PrivacyTitleTxt">The Evouchr Group.</h2>
                        </div>
                        <div className="MainPrivcyPageDesc TextStyleDesign">
                            <p>
                                Evouchr is an e-commerce platform specializing in the resale of closed-loop gift cards, vouchers, phone top-ups, Product or Products
                                {/* <a href="https://evouchr.com/" target="_blank"  className="HyperLinkDesign">gift cards</a>, */}
                                {/* <a href="https://evouchr.com/" target="_blank"  className="HyperLinkDesign"> vouchers</a> and */}
                                {/* <a href="https://evouchr.com/" target="_blank"  className="HyperLinkDesign"> phone top-ups</a>  */}
                                {/* <a href="https://evouchr.com/" target="_blank"  className="HyperLinkDesign"> ("Product" or "Products") </a>  */}
                                of fixed or variable amounts, available in various currencies. These can be redeemed by the Customer at the issuer's store in exchange for the services and products offered by the issuer.
                            </p>
                            
                            <p>
                            Evouchr provides a range of Products based on the Customer's country. Customers can select their country to view all available offers. It's important to note that Evouchr does not guarantee the cross-country functionality of a Product purchased from one country page when used in another country. Therefore, Customers are strongly advised to review the terms and conditions of each specific Product.
                            </p>
                            
                            <p>
                            While Evouchr endeavors to present updated terms and conditions for all products, it does not make any representations in that regard. Customers should verify the applicable terms and conditions of the Product directly on the issuer's website before making a purchase. Certain Products may have geographical restrictions limiting them to specific countries. In such cases, attempting to purchase from a country not allowed by a Product's terms and conditions will result in an impossibility of the transaction.
                            </p>
                            
                            <p>
                            Upon selection and payment, the Customer will receive access to the Product either on screen or via email to their email account.
                            </p>
                            
                            <p>
                            While Evouchr aims to maintain transparent expected timescales, it is not responsible for delays and losses caused by the Customer or third parties.
                            </p>
                        </div>
                    </div>

                    <div>
                        <hr/>
                    </div>


                    <div className="PrivacytextOne">
                        <div className="MainPrivacyTitleTxt">
                            <h2 className="PrivacyTitleTxt">The payment</h2>
                        </div>
                        <div className="MainPrivcyPageDesc TextStyleDesign">
                            <p>
                            The Products can be acquired by the Customer either<br/>
                            (i) directly using a cryptocurrency,<br/>
                            (ii) through a third-party payment provider, accepting either fiat or cryptocurrency, or<br/>
                            (iii) utilizing a Evouchr gift card ("Balance Card") or store credit balance ("Balance").
                            </p>
                        </div>
                    </div>

                    <div>
                        <hr/>
                    </div>
                    
                    <div className="PrivacytextOne">
                        <div className="MainPrivacyTitleTxt">
                            <h2 className="PrivacyTitleTxt">Payment with crypto</h2>
                        </div>
                        <div className="MainPrivcyPageDesc TextStyleDesign">
                            <p>
                            Customers have the option to make direct payments using one of the cryptocurrencies accepted by Evouchr at the time of purchase or through a third-party crypto payment service available at the check-out. These transactions may be subject to fees and confirmation time, as outlined in Evouchr's terms or in the terms and conditions of such third-party providers.
                            </p>
                        </div>
                    </div>
                    
                    <div>
                        <hr/>
                    </div>

                    <div className="PrivacytextOne">
                        <div className="MainPrivacyTitleTxt">
                            <h2 className="PrivacyTitleTxt">Disclaimers related to the Product.</h2>
                        </div>
                        <div className="MainPrivcyPageDesc TextStyleDesign">
                            <p>
                            We are a reseller of the Product. We are not the creator, designer, issuer, organizer, beneficiary or manager of the Product (the “Issuer”), nor have we played any role in deciding the terms and conditions applicable to each Product. Therefore, we are not liable for any issue, damage or loss arising from the terms and conditions of each individual Product.
                            </p>
                            
                            <p>
                            We are not sponsored by any Issuer, nor are we related, acting on behalf or under the express authorisation of any Issuer. We are not liable for any malfunction or any infringement of applicable rules caused by the Issuer.
                            </p>
                            
                            <p>
                            The Customer, by acquiring the Product, is also entering into a contractual relationship with the Issuer, to which we are a third-party, against which the contractual defences and rights arising from the relationship between Customer and Issuer shall not apply.
                            </p>
                            
                            <p>
                            The Customer shall engage in adequate due diligence regarding the terms and conditions of the Product being acquired, in what respects its usability and enforcement against the Issuer. We will not accept any liability for any claims in relation thereto. Those terms and conditions govern the Product acquired.
                            </p>
                            
                            <p>
                            Customers acknowledge and agree that we are not liable for any decision or policy of the Issuer to refuse to accept the Product on the grounds of the terms and conditions that the Issuer applies in relation to the Product.
                            </p> 
                            
                            <p>
                            We make no representation regarding the Product’s suitability for its intended use and make no other guarantees, warranties or representations in respect of the Product.
                            </p>
                        </div>
                    </div>
                    
                    <div>
                        <hr/>
                    </div>

                    <div className="PrivacytextOne">
                        <div className="MainPrivacyTitleTxt">
                            <h2 className="PrivacyTitleTxt">Responsibility of the Customer.</h2>
                        </div>
                        <div className="MainPrivcyPageDesc TextStyleDesign">
                            <p>
                            The Customer is fully liable for any mistakes, losses or damages (against himself, us, or any third-party) arising from
                            </p>
                            
                            <p>
                            (i) the provision of incorrect, inaccurate or fake information and from
                            (ii) the acquisition, resale, use or other related activities of the Product, in a manner that infringes any applicable laws, regulations, contractual terms and conditions or other agreements.
                            </p>

                            <p>
                            For the avoidance of doubt, when the Product being purchased is a refill of a prepaid SIM card (or any other Product that is redeemed by the Customer through Evouchr), the Customer is solely responsible for the accuracy and correctness of the information inserted on the platform (including, but not limited to, the phone number, the country, the network operator and the amount of the top-up) and we shall not be liable, in any case, for any consequences arising from the inaccuracy of the elements provided by the Customer.
                            </p>

                            <p>
                                Likewise, in other cases, the Customer is solely responsible for the accuracy, validity and correctness of the information inserted on the platform (including, but not limited to, the email address to which the Product must be delivered and the amount purchased).
                            </p>

                            <p>
                            The Customer is responsible for maintaining the confidentiality of its password and account and for any activities and transactions that occur under its account. Each customer must have unique login credentials that may not be shared by multiple customers. We shall not be liable to any person for any loss or damage which may arise as a result of any failure by you to protect your password or account. If you suspect there has been unauthorized access to your account, contact us at Evouchr immediately.
                            </p>
                            
                        </div>
                    </div>
                    
                    <div>
                        <hr/>
                    </div>

                    <div className="PrivacytextOne">
                        <div className="MainPrivacyTitleTxt">
                            <h2 className="PrivacyTitleTxt">Unlawful use.</h2>
                        </div>
                        <div className="MainPrivcyPageDesc TextStyleDesign">
                            <p>
                            Evouchr and the Products shall not be used, in any way, to infringe or circumvent the applicable laws and regulations and any unlawful behavior is absolutely forbidden on Evouchr.
                            Should we become aware, or reasonably suspect, that a Customer is conducting unlawful activities, or otherwise breaking applicable laws and regulations, or third-party rights, such Customer activities, including but not limited to its purchase history and other data pertaining to its account, will be reported to the relevant authorities, and its access may be restricted until further notice.
                            </p>

                            <p>
                            Any such Customer hereby acknowledges and consents to such disclosures.<br/>
                            Evouchr is also entitled to freeze any existing balances and/or accounts for as long as needed for the purpose of investigating suspicious behavior. We may be required to not disclose any such ongoing investigations to Customers, in which case we will keep them confidential. Any store credit held in a Evouchr Balance that is found to have been paid with proceedings of crime may be seized and delivered to legal authorities.

                            </p>

                            <p>
                            Customers are intended end-users of the Products. If you want to purchase Products for resale, please reach out to us at Evouchr and we will guide you through the process. If we suspect you are buying Products for resale, we may freeze your account until you verify as a Corporate Customer.
                            </p>

                            <p>
                            Furthermore, Customers agree to not abuse the Evouchr platform by: <br/>
                            1. Engaging in actions which impose an unreasonable or disproportionately large load on our infrastructure; <br/>
                            2. Employing uses of robots, spider or other automated means to access, or when accessing, BiEvouchr; <br/>
                            3. Interfering with, intercepting, modifying or expropriating any system, data, or information from Evouchr serves;<br/>
                            4. Transmitting or uploading any material that contains viruses, trojan horses, worms, or any other harmful or deleterious programs;<br/>
                            5. Attempting to gain unauthorized access to the Evouchr Products, other Evouchr accounts, computer systems, or networks connected to the Evouchr servers through password mining or any other means.
                            </p> 


                        </div>
                    </div>

                    <div>
                        <hr/>
                    </div>

                    <div className="PrivacytextOne">
                        <div className="MainPrivacyTitleTxt">
                            <h2 className="PrivacyTitleTxt">Personal Data Protection and Privacy.</h2>
                        </div>
                        <div className="MainPrivcyPageDesc TextStyleDesign">
                            <p>
                                We take the privacy and protection of the personal data of our Customers very seriously. Customers shall visit and review our Privacy Policy to know more about how we process and protect their data and shall not proceed with using Evouchr if it does not agree and consent with it.
                            </p>
                        </div>
                    </div> 
                    
                     <div>
                        <hr/>
                    </div>

                    <div className="PrivacytextOne">
                        <div className="MainPrivacyTitleTxt">
                            <h2 className="PrivacyTitleTxt">Customer service.</h2>
                        </div>
                        <div className="MainPrivcyPageDesc TextStyleDesign">
                            <p>
                                We welcome our Customer’s questions and suggestions. On Evouchr, there is a chat function that allows Customers to connect with a customer service representative that will reply to any queries in the shortest time possible. We do our best to reply within the first 24 hours, but we may take longer, depending on the issue and the number of requests we are getting from time to time.
                            </p>
                        </div>
                    </div>

                </div>

            </div>
        </>
    )
}
export default MobileTermsNdCondition